import React, { useState, useEffect, useRef } from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';

import arrowIcn from '../../assets/icns/arrow.svg'; 


import arrowsMockup from '../../assets/imgs/arrows/arrows-interface-mockup.png'; 
import arrowsHeadLips from '../../assets/imgs/arrows/arrows-lips-heads.png'; 
import arrowsUnityAnimator from '../../assets/imgs/arrows/arrows-animator-unity.png'; 


const ArrowsPage = () => {
  
  const videos = [
    `https://paz-roth-videos.s3.eu-west-3.amazonaws.com/navina_mockups0002.mp4`,
    `https://paz-roth-videos.s3.eu-west-3.amazonaws.com/navina_mockups0003.mp4`,
    `https://paz-roth-videos.s3.eu-west-3.amazonaws.com/navina_mockups0004.mp4`,
    `https://paz-roth-videos.s3.eu-west-3.amazonaws.com/navina_mockups0005.mp4`,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRefs = useRef([]);

  useEffect(() => {
      // Preload all videos
      videoRefs.current.forEach(video => {
          if (video) {
              video.load();
          }
      });
  }, []);

  const prevSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? videos.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex === videos.length - 1 ? 0 : prevIndex + 1));
  };


  const redirectToArrows = () => {
    window.location.href = '/works/arrows';
  };
  
  const redirectToVayyar = () => {
    window.location.href = '/works/vayyar';
  };


  return (

    <div className='row p-0 m-0'>
    <Header/>
    <div className='header-height'></div>

    <div className='p-md-5'>
        <div className='row p-0 m-0 '>
          <div className='col-sm-1 col-xs-12'></div>
          <div className='col-sm-11 col-xs-12'>
          <div className='row'>
              <div className='col d-flex'>
                  <h4 className='fw-500 fs-28 capitalize'>Arrows</h4>
              </div>
                <div className='col d-flex float-end justify-content-end'>
                  <p className='fs-18 fw-300'>2023</p>
                </div>
             </div>
                <div className='col-12'>
                    <h4 className='fs-60 fw-400'>
                    Making a Virtual agent for enhancing social skills interaction interface - arrows
                                        </h4>
                <img className='pt-5 img-fluid' src={arrowsMockup} alt=""/>
            </div>     
          </div>
        </div> 



          <div className='row px-5 py-5 m-0'>

          <div className='col-sm-1 col-xs-12 '>
          </div>

              <div className='col-sm-11 col-xs-12 py-5'>
                <div className='d-flex col-10'>
                  <p className='fs-22 fw-300'>
                  This work is a collaboration with Arrows team; and includes making a realistic human avatar for WebGL using Unity, facial rig, lips-sync, and developing JS and C# sharp functions and data transfer using unity-react-webGL interaction to control the avatar inside the game engine that will get audio source from external AI resource based on the speaking interaction between the users and the tutor avatar.                  </p>
                </div>
 
                  <div className='py-3'></div>

                    <div className='col-12'>
                      <p className='fs-18 fw-400'>This work includes</p>
                        <div className='row'>
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Capabilities</h4>
                              <ul className='p-0'>
                                <li className='py-1'>3D modeling</li>
                                <li className='py-1'>3D Animation</li>
                                <li className='py-1'>Rigging</li>
                                <li className='py-1'>C# Programing</li>
                                <li className='py-1'>React JS</li>

                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Team</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Programmer</li>
                                <li className='py-1'>Animator</li>
                                <li className='py-1'>Designer</li>
                                <li className='py-1'>Product Manager</li>

                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Cooperation</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Project</li>
  
                              </ul>
                          </div>

                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Industry</h4>
                                <li className='py-1'>Tech, Education</li>
                                <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                                <li className='py-1'>4 Months</li>

                          </div>

                        </div>

                        <div className='my-3 '></div>

                      <div className='d-flex'>
                          <h4 className='fs-22 fw-700 dark-text'><a href='https://ar-rows.com' target='blank'>Visit Website &nbsp;↗</a> </h4>
                        </div>

                        </div>



                </div>

          </div>



          <div className='row m-0 px-5'>
            <div className='col-12'>
              <video
                  className='video'
                  src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/arrows-interface.mp4"
                  autoPlay
                  muted
                  loop
                  style={{ maxWidth: '100%' }}
                />
            </div>
          </div>


          <div className='row px-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
              <h4 className='fw-400 fs-22 pb-3 capitalize opacity-50'>Social Skills Coach</h4>
                <p className='fs-22 fw-200'>

                A SaaS platform for learning and practicing social skills through realistic conversations with avatars based on Generative AI, grounded in a leading evidence-based program, empowering social skills. Arrows provide a safe environment for dealing with various complex social situations.

The Skill Coach is a virtual social coach based on AI, which identifies errors in real time and provides users with information and recommendations through the projection of visual cues and textual feedback to improve skills.                  </p>
            </div>
          </div>



          <div className='row m-0 px-5'>
            <div className='col m-2'>


            </div>
            <div className='col-xs-12'>
                <img
                  src={arrowsHeadLips}
                  width='100%'
                />
            </div>

          </div>
 


          <div className='row px-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
            <h4 className='fw-400 fs-22 pb-3 capitalize opacity-50'>Design</h4>

              <p className='fw-200 fs-22 pb-3 '>
              the work started with draft sketches of the desired avatar for Arrow's interface; Arrow's targeted audience is people on the autistic spectrum. We wanted to make a woman avatar that would feel gentle and welcoming, using the noncomplex elements to avoid distraction.
              After agreeing on the look and feel of the avatar, the next step was to make the facial rig and the lips sync. The facial rig is made with controllers and a GUI window to achieve simple emotions such as happiness, sadness, and anger and animate head and eye movements.
              </p>

            </div>


          <div className='col-sm-9 col-xs-12'>
          <video
                  className='video'
                  src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/arrows-controls-video_900x900.mp4"
                  autoPlay
                  muted
                  loop
                  style={{ maxWidth: '100%' }}
                />

          </div>

          </div>



          <div className='row px-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
              <h4 className='fw-400 fs-22 pb-3 capitalize opacity-50'>Combining all together</h4>
              <p className='fw-200 fs-22 pb-3'>
                After we finished the design process and the avatar was ready, we took all the pieces into the game engine to program the state machine for the avatar's lips sync, sound, and reactions according to the user input, which was the most challenging part of this work.
              </p>
            </div>

            <div className='col-sm-7 col-xs-12'>
            <video
                  className='video'
                  src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/arrows-avatar.mp4"
                  autoPlay
                  muted
                  loop
                  style={{ maxWidth: '100%' }}
                />
            </div>

          </div>





          <div className='row px-sm-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
              <h4 className='fw-400 fs-22 pb-3 opacity-50'>Results</h4>
              <h4 className='fs-42 fw-400'>Practice Social Skills Interface With Real Animated Avatar</h4>



              </div>
          </div>



          <div className='row px-sm-5 m-0'>
              <div className='col d-flex'>
                  <p onClick={redirectToVayyar} className='fw-600 pointer'>Previous</p>
              </div>
              <div className='col d-flex text-right justify-content-end '>
                  <p onClick={redirectToArrows} className='fw-600 pointer'>Next</p>
              </div>
          </div>  


            <div className='row pb-5 px-sm-5 m-0'>
                <Footer/>
            </div>
        </div>


        
    </div>






  );
};

export default ArrowsPage;
