import React from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';

import otterizepanel from '../../assets/imgs/otterize/otterize-panel-img.gif'; 
import otterizeCubes from '../../assets/imgs/otterize/otterize-cubes-img.png'; 
import otterizeServicePanel from '../../assets/imgs/otterize/otterize-service-panel-img.gif'; 
import otterizeServicePanelLines from '../../assets/imgs/otterize/otterize-service-panel-lines.png'; 
import otterizeServicePanelTransparent from '../../assets/imgs/otterize/otterize-service-panel-transparent.png'; 
import otterizeStoryboard from '../../assets/imgs/otterize/otterize-storyboard-img.png'; 

import reviewerImg from '../../assets/imgs/otterize/reviewer.png'; 








const redirectToArrows = () => {
  window.location.href = '/works/arrows';
};

const redirectToVayyar = () => {
  window.location.href = '/works/vayyar';
};




const OtterizePage = () => {
  return (
    <div className='row p-0 m-0'>
    <Header/>

    <div className='header-height'></div>

    <div className='p-md-5'>
        <div className='row p-0 m-0 '>
          <div className='col-sm-1 col-xs-12'></div>
          <div className='col-sm-11 col-xs-12'>
          <div className='row'>
              <div className='col d-flex'>
                  <h4 className='fw-500 fs-28 capitalize'>Otterize</h4>
              </div>
                <div className='col d-flex float-end justify-content-end'>
                  <p className='fs-18 fw-300'>2023</p>
                </div>
             </div>
                <div className='col-12 mb-4 pb-4'>
                    <h4 className='fs-60 fw-400 capitalize'>
                    Making a short animated explainer video for Otterize - an automated policy management tool
                    </h4>
            </div>     
            <div className='col-12 mt-2 pt-4'>
              <video
                  className='video'
                  src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/Otterize.mp4"
                  loop
                  controls
                  style={{ maxWidth: '100%' }}
                />
            </div>


              <div className='col-sm-10 col-xs-12 my-4'>
                <div className='col-12 py-4'>
                  <p className='fs-22 fw-300 pt-4 pb-4'>
                  Otterize makes it a breeze to find sensitive services in your Kubernetes cluster.
                  We collabortaed with otterize to create a promotional film for the launch of their policy management interface. They wanted to explain their solution with a colorful motion-designed animated video to get people's attention about the Otterize solution.
                  </p>
                </div>
 

                </div>
                <div className='col-sm-12 col-xs-12'>
                <div className='my-4 p-0'>
                  <img className='img-fluid' src={otterizepanel} alt=""/>

                </div>



                  <div className='py-3'></div>

                    <div className='col-12'>
                      <p className='fs-18 fw-400'>This work includes</p>
                        <div className='row'>
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Capabilities</h4>
                              <ul className='p-0'>
                                <li className='py-1'>3D Animation</li>
                                <li className='py-1'>Explainer Video</li>
                                <li className='py-1'>Interface Design</li>
                                <li className='py-1'>User Experience</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Team</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Marketing Manger</li>
                                <li className='py-1'>Animator</li>
                                <li className='py-1'>Voice Actor</li>
                                <li className='py-1'>Sound Designer</li>

                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Cooperation</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Project</li>
  
                              </ul>
                          </div>

                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Industry</h4>
                                <li className='py-1'>SaaS, Tech</li>
                                <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                                <li className='py-1'>2 Months</li>

                          </div>

                        </div>

                        <div className='py-3'></div>
    
                        {/* <div className='col d-flex'>
                            <h4 className='fs-22 fw-600'>Reviews</h4>
                        </div> */}
                        <div className='my-3 '></div>

                      <div className='d-flex'>
                          <h4 className='fs-22 fw-700 dark-text'><a href='https://otterize.com' target='blank'>Visit Website &nbsp;↗</a> </h4>
                        </div>

                        </div>



                </div>

                </div>





          <div className='row py-4 my-4'>
          <div className='col-4 px-5 py-5'>
          </div>

            <div className='col px-5 py-5'>
              <h4 className='fw-400 fs-22 pb-3 opacity-50'>Approach</h4>
                <p className='fs-22 fw-200'>
                    Together with Otterize Marketing Leader, we define the aim of the final product: Is this an explanation of the features, a video for product release, or a call to action video? Could it be both? Is a film the best way to communicate that aim?
                    After figuring out all these questions, we agreed that the video should focus on what the product offers and end with a call to action - calling to try and map your cluster.
                  </p>
                  <p className='fs-22 fw-200'>
                  We've built a thumb storyline to combine all these key points and create the narration and script that will present these key points in the best way possible in a 90-second video. The final strategy was to make a video showing what Otterize is and include it on the company website and marketing materials.
We like to take on challenges, and this project came with the challenge of understanding what policy management means!? Then, jump into the mind of someone who knows what it means to make a video that speaks their language.                  </p>
              </div>
            </div>




 



          <div className='row px-sm-5 pb-5 m-0'>
            <div className='row m-0 px-5'>
              <div className='col-6 p-2'>
                  <img
                    src={otterizeCubes}
                    width='100%'
                  />
              </div>
              <div className='col-6 p-2'>
                  <img
                    src={otterizeServicePanel}
                    width='100%'
                  />
              </div>
              <div className='col-6 p-2'>
                  <img
                    src={otterizeServicePanelLines}
                    width='100%'
                  />
              </div>
              <div className='col-6 p-2'>
                  <img
                    src={otterizeServicePanelTransparent}
                    width='100%'
                  />
              </div>
            </div>

            <div className='col-2 px-5 py-5'>
          </div>
          <div className='col-12 mt-4 pt-4 px-5'>
            <h4 className='fw-400 fs-22 pb-3 opacity-50'>Storyboard</h4>
                <p className='fs-22 fw-200'>
                  The concept idea was to present how the experience was before Otterize policy management, when the PODs were flying over, and the users needed to figure out what connected to where.
                  </p>
                  <p className='fs-22 fw-200'>
                    However, after integrating with Otterize, the PODs transitioned into a clear and organized platform that maps the connection and provides the relevant information to decrease users' time spent problem-solving.
                  </p>
                      
                  <div className='my-4 py-4'>
                    <img
                      src={otterizeStoryboard}
                      width='100%'
                    />
                    </div>
                </div>
          </div>





    

{/* 
          <div className='row px-sm-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
              <h4 className='fw-600 fs-22 pb-3 opacity-50'>Results</h4>
              <h4 className='fs-42 fw-600'>Easy Explains Navina's Product</h4>
              <div className='ro border-bottom-dark-1px'>
                  <div className='col-sm-11 col-xs-12 container text-center capitalize'>
                      <div className='row px-5 py-5'>
                        <div className='col-md-4 col-sm-12 p-4'>
                            <h4 className='fs-60 fw-700'>60%</h4>
                              <p className='fs-24 fw-400 '>more engagments</p>
                          </div>
                          <div className='col-md-4 col-sm-12 p-4'>
                            <h4 className='fs-60 fw-700'>50%</h4>
                              <p className='fs-24 fw-400 '>increase in website time view</p>
                          </div>
                          <div className='col-md-4 col-sm-12 p-4'>
                            <h4 className='fs-60 fw-700'>55%</h4>
                              <p className='fs-24 fw-400 '>growth in leads</p>
                          </div>

                      </div>
                    </div>
                  </div>
              </div>
          </div> */}


          <div className='row px-sm-5 pb-5 m-0'>
              <div className='col-12 px-5 pb-5'>
                  <p className='fs-22 fw-200'>
                  We've had the privilege of collaborating with Fezzik on the great marketing animated explanation video we've made together. The video plays a dominant role in our marketing presence and helps us briefly explain what we do in one minute, making the rest of the marketing much smoother. This collaboration has been instrumental in creating a seamless user experience for our potential customers. From ideation to execution, this partnership has exemplified synergy and dedication, resulting in a product we are truly proud of. 
                                    </p>

                  <div className='d-flex pt-4'>
                    <img
                    src={reviewerImg}
                    width='60px'
                    height='60px'
                    />
                    <p className='fs-18 fw-300 ms-3'>Ania Varsh<br></br>
                      <span className='fw-500 fs-16'>Senior Marketing Director</span></p>
                  </div>
              </div>
          </div>



          <div className='row px-sm-5 m-0'>
              <div className='col d-flex'>
                  <p onClick={redirectToVayyar} className='fw-600 pointer'>Previous</p>
              </div>
              <div className='col d-flex text-right justify-content-end '>
                  <p onClick={redirectToArrows} className='fw-600 pointer'>Next</p>
              </div>
          </div>  


            <div className='row pb-5 px-sm-5 m-0'>
                <Footer/>
            </div>
  
    </div>
    </div>

    </div>



  );
};


export default OtterizePage;


