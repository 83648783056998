import React from 'react';
import Header from '../../header/Header';
import astrixCubesAlert from '../../assets/imgs/astrix/cubes-alert.png'; 
import astrixCubesparty from '../../assets/imgs/astrix/cubes-3rd-party.png'; 
import astrixCore from '../../assets/imgs/astrix/core-system-img.png'; 
import astrixConnectivity from '../../assets/imgs/astrix/cubes-connectivity-map.png'; 

import Footer from '../../footer/Footer';


const redirectToArrows = () => {
  window.location.href = '/works/arrows';
};

const redirectToVayyar = () => {
  window.location.href = '/works/vayyar';
};




const AstrixPage = () => {
  return (
    <div className='row p-0 m-0'>
    <Header/>
    <div className='header-height'></div>

    <div className='p-md-5'>
        <div className='row p-0 m-0 '>
          <div className='col-sm-1 col-xs-12'></div>
          <div className='col-sm-11 col-xs-12'>
          <div className='row'>
              <div className='col d-flex'>
                  <h4 className='fw-500 fs-28 capitalize'>Astrix</h4>
              </div>
                <div className='col d-flex float-end justify-content-end'>
                  <p className='fs-18 fw-300'>2023</p>
                </div>
             </div>
                <div className='col-12 mb-4 pb-4'>
                    <h4 className='fs-60 fw-400 capitalize'>
                      Explaining the app to app cyber security challenges
                    </h4>
            </div>     
            <div className='col-12 mt-2 pt-4'>
              <video
                  className='video'
                  src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/Astrix.mp4"
                  loop
                  controls
                  style={{ maxWidth: '100%' }}
                />
            </div>


              <div className='col-sm-10 col-xs-12 my-4'>
                <div className='col-12 py-4'>
                  <p className='fs-22 fw-300 pt-4 pb-4'>
                    We teamed up with Astrix to create a short animated movie explaining Astrix's platform coming to solve. We build the script to cover three sections, describing the situation, an overview of the problem, and a short teaser of the solution.
                  </p>
              </div>
 

                </div>
                <div className='col-sm-12 col-xs-12'>
                  <div className='py-3'></div>
                    <div className='col-12'>
                      <p className='fs-18 fw-400'>This work includes</p>
                        <div className='row'>
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Capabilities</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Animation</li>
                                <li className='py-1'>Explainer Video</li>
                                <li className='py-1'>User Experience</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Team</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Marketing Manger</li>
                                <li className='py-1'>Animator</li>
                                <li className='py-1'>Voice Actor</li>
                                <li className='py-1'>Sound Designer</li>

                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Cooperation</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Project</li>
  
                              </ul>
                          </div>

                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Industry</h4>
                                <li className='py-1'>Cyber Security</li>
                                <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                                <li className='py-1'>1.5 Months</li>

                          </div>

                        </div>

                        <div className='py-3'></div>
    
                        {/* <div className='col d-flex'>
                            <h4 className='fs-22 fw-600'>Reviews</h4>
                        </div> */}
                        <div className='my-3 '></div>

                      <div className='d-flex'>
                          <h4 className='fs-22 fw-700 dark-text'><a href='https://astrix.security' target='blank'>Visit Website &nbsp;↗</a> </h4>
                        </div>

                        </div>



                </div>

                </div>





          <div className='row py-4 my-4'>
          <div className='col-4 px-5 py-5'>
          </div>

            <div className='col px-5 py-5'>
              <h4 className='fw-400 fs-22 pb-3 opacity-50'>Story Stracture</h4>
                <p className='fs-22 fw-200'>
                  We started this artwork video by refining the purpose of the final material; these were made with a questionnaire and video meeting with Astrix's team to collect data about the functions the product has to offer. The result was to build a script that briefly describes the problem the audience has in the beginning, explains why that happens, and gives a sneak pick at the product of Astrix in a few seconds.
                </p>
              </div>
            </div>




 



          <div className='row px-sm-5 pb-5 m-0'>
            <div className='row m-0 px-5'>
              <div className='col-6 p-2'>
                  <img
                    src={astrixCubesAlert}
                    width='100%'
                  />
              </div>
              <div className='col-6 p-2'>
                  <img
                    src={astrixCubesparty}
                    width='100%'
                  />
              </div>
              <div className='col-6 p-2'>
                  <img
                    src={astrixCore}
                    width='100%'
                  />
              </div>
              <div className='col-6 p-2'>
                  <img
                    src={astrixConnectivity}
                    width='100%'
                  />
              </div>
            </div>

            <div className='col-2 px-5 py-5'>
          </div>
          <div className='col-12 mt-4 pt-4 px-5'>
            <h4 className='fw-400 fs-22 pb-3 opacity-50'>Design Goal</h4>
                <p className='fs-22 fw-200'>
                  We intended to devise a simple way to present the problem even to another related crowd needing help understanding this environment. And for simple ideas, we have simple cubes. We used toon shaders and transparent materials to lift the design and give the frames a modern, updated look.
                </p>
              </div>
          </div>

    


          <div className='row px-sm-5 m-0'>
              <div className='col d-flex'>
                  <p onClick={redirectToVayyar} className='fw-600 pointer'>Previous</p>
              </div>
              <div className='col d-flex text-right justify-content-end '>
                  <p onClick={redirectToArrows} className='fw-600 pointer'>Next</p>
              </div>
          </div>  


            <div className='row pb-5 px-sm-5 m-0'>
                <Footer/>
            </div>
  
    </div>
    </div>

    </div>

  );
};


export default AstrixPage;


