import React from 'react';
import Header from '../header/Header';
import arch_about from '../assets/imgs/arch/about_image.webp'
import arch_example_1 from '../assets/imgs/arch/basel_a.webp'
import arch_example_2 from '../assets/imgs/arch/basel_b.webp'
import arch_example_3 from '../assets/imgs/arch/bedroom.webp'

const ArchPage = () => {


    const redirectToProposal = () => {
        window.location.href = '/proposal';
      };

      
  return (  
    <div className='light-background dark-text'>
        <Header isDarkBackground={false}/>


<section className="full-height-section">
    <div className='container p-0 m-0 h-100'>
        <div fluid className="row h-100">
          <div className="col-md col-sm col-xs-12 p-0">
            <div className="video-container">
              <video autoPlay loop muted className="">
                <source src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/arch-video-bg.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="col-md-4 col-sm col-xs-12 d-flex align-items-center justify-content-center px-5">
           <div className='row m-0'>
            <h3 className='mb-4'>
            <span className='fs-16'>3D RENDERINGS</span><br></br>
                Architectural
                Visualization
            </h3>
                 <p className='fs-18 fw-400'>
                    Fezzik delivers premium-grade visuals including images, animations, immersive walkthroughs, and 360-degree virtual reality tours tailored for the architecture and real estate sectors
                </p>


           </div>

          </div>
        </div>
        </div>
    </section>

    <section className="full-height-section">
    <div className='container p-0 m-0 h-100'>
        <div fluid className="row h-100">
        <div className="col-md-5 col-sm col-xs-12 d-flex align-items-center justify-content-center px-5">
           <div className='row  m-0'>
            <h3 className='mb-4'>
            <span className='fs-16'></span><br></br>
                Bringing your CAD
                to Life
            </h3>
            <p className='fs-18 fw-400'>
            We provide our clients with top-class photorealistic 3D rendering services including animations, still images, walkthroughs and virtual reality tours. We bring designs to life with the latest technologies. 
                </p>
                <div className='d-inline-flex my-3' onClick={redirectToProposal}>
                    <p className='border-dark-around px-3 rounded-5 fs-18 fw-600 pointer'>Get Proposal</p>
                </div>
           </div>
          </div>
          <div className="col-md col-sm col-xs-12 p-0">
            <div className="row img-container">
                    <img className='img-fluid' src={arch_about} alt='arch about'/>

            </div>
          </div>

        </div>
        </div>
    </section>

        <div fluid className="row p-0">
         <div className="col-sm-12 col-xs-12">
         <div className="col-sm-12 col-xs-12">
            <img className='img-fluid' src={arch_example_3} alt='arch about'/>
          </div>
            <img className='img-fluid' src={arch_example_1} alt='arch about'/>
          </div>
          <div className="col-sm-12 col-xs-12">
            <img className='img-fluid' src={arch_example_2} alt='arch about'/>
          </div>
        </div>



            <div className='row px-5 py-5 m-0 align-content-center'>
            <div className='col-md col-sm col-xs-12 my-3'>
                <p className='fs-22 m-0 p-0 fw-400'>Reach out</p>
                  <a className='fs-36 fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art&nbsp;↗</a>
                    <br></br>
                    {/* <a className='fs-24 light-text fw-500' href="https://api.whatsapp.com/send?phone=972528450488">Whatsapp&nbsp;↗</a> */}
               </div>
               {/* <div className='col-md col-sm col-xs-12 my-3 '>
                <p className='fs-22 m-0 p-0 fw-400 '>Socials</p>
                  <a className='fs-18 fw-200 border-bottom-dark-1px py-1 me-3' href="https://www.instagram.com/fezzikart/" target='blank'>Instagram</a>
                  <a className='fs-18 fw-200 border-bottom-dark-1px py-1 me-3' href="https://www.linkedin.com/company/fezzik-art/?viewAsMember=true" target='blank'>Linkedin</a>
                  <a className='fs-18 fw-200 border-bottom-dark-1px py-1 me-3' href="/" target='blank'>Vimeo</a>
                  <a className='fs-18 fw-200 border-bottom-dark-1px py-1' href="https://www.youtube.com/@fezzik_art" target='blank'>Youtube</a>


               </div> */}
            </div>

        </div>



     
  );
};

export default ArchPage;
