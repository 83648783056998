import React from 'react';
import Header from '../../header/Header';
import dhcShape from '../../assets/imgs/dhc/dhc-shape-loop.png';
import dhcPageOne from '../../assets/imgs/dhc/dhc-page.png'; 
import dhcPageTwo from '../../assets/imgs/dhc/mockup-dhc-booklet.png'; 
import dhcPageThree from '../../assets/imgs/dhc/mockup-dhc-paper.png'; 
import dhcPageFour from '../../assets/imgs/dhc/mockup-dhc-website.png'; 
import dhcPageFive from '../../assets/imgs/dhc/mockup-ukio.png'; 
import ContactButton from '../../footer/Footer';





const redirectToArrows = () => {
  window.location.href = '/works/arrows';
};

const redirectToVayyar = () => {
  window.location.href = '/works/vayyar';
};




const DhcPage = () => {
  return (
    <div className='light-background h-100'>
    <Header/>

    <div className='header-height'></div>


    <div className='row px-5 mb-5'>
    <div className='header-height m-sm-5'></div>

    <div className='col-md-2 col-xs-12'>
        </div>

            <div className='col-md col-xs-12'>
              <div className='row'>
                <div className='col-8 d-flex'>
                  <h4 className='fw-400 fs-24'>Diagnostic Healthcare Reshape</h4>
 
            </div>
              <div className='col d-flex float-end justify-content-end'>
                <p className='fs-18 fw-300'>2023</p>
              </div>
            </div>


              <div className='col-md-10 col-xs-12'>
                 <p className='fs-18 fw-400'>
                 A comprehensive reshaping of branding materials for our friends at Diagnostic Healthcare, bringing their care spirit and focus on safety & quality to life via clean and minimalized brand language.
                                 </p>
              </div>

              <div className='mt-5'>
                <img className='img-fluid' src={dhcShape} alt=""/>
              </div>



            <div className='row'>
            <div className='col-md-12 col-xs-12 py-3'>
                  <img className='img-fluid' src={dhcPageFive} alt=""/>
                </div>
                <div className='col-md-12 col-xs-12 py-3'>
                  <img className='img-fluid' src={dhcPageThree} alt=""/>
                </div>
                <div className='col-md-12 col-xs-12 p-2'>
                  <img className='img-fluid' src={dhcPageTwo} alt=""/>
                </div>
                <div className='col-md-12 col-xs-12 p-2'>
                  <img className='img-fluid' src={dhcPageFour} alt=""/>
                </div>

                <div className='col-md-12 col-xs-12 p-2'>
                  <img className='img-fluid' src={dhcPageOne} alt=""/>
                </div>
              </div>
            </div>
          </div>



          <div className='header-height'></div>



          <div className='row px-5 mb-5'>

          <div className='col'>
              <div onClick={redirectToVayyar}><p className='fw-100 pointer'>Previous</p></div>
            </div>

            <div className='col float-end '>

              <div onClick={redirectToArrows}><p className='fw-400 pointer'>Next</p></div>
            </div>
          </div>  



    <ContactButton/>
  </div>
  );
};


export default DhcPage;


