import React from 'react';
import Header from '../header/Header';



const AboutPage = () => {
  return (
    <div className='light-background row container maxWidth-1920 mx-auto p-0 m-0'>
      <Header/>
      <div className='header-height'></div>
          <div className='row align-items-center align-middle px-5'>
            
        <div className='col-md-9 col-xs-12'>
            <div className='row mb-5'>
            <div className='col-md-12 col-xs-12 mb-5'>
            <p className='fw-400 text-dark fs-32'>
                Fezzik is a talented production, storytelling, creative company.
                Since 2014, we have been creating animation, design, and development productions for companies, startups, and teams all over the globe. We bring our creative ambition to each one of them.
                </p>
      
                <p className='fw-100 text-dark fs-32'>
                    Our Mission Is To Provide Artworks That Help Deliver Messages, Make An Empact And Call To Action.
                </p>
            </div>

            <div className='col-md-12 col-xs-12 my-3'>
                <h3 className='fs-24 fw-600'>
                    Our things
                </h3>
                <p className='fw-300 text-dark fs-22'>
                    We make a wide range of artworks, such as Movies, Brand Videos, Explainer Videos, Product Videos, Pitch Videos, Branding, Demonstrations, User Experience Research, Web Development, Apps, and Virtual and Augmented Reality.
                </p>
      
            </div>

            <div className='col-md-12 col-xs-12 my-3'>
                <h3 className='fs-24 fw-600'>
                    Between Our Clients
                </h3>
                <p className='fw-300 text-dark fs-22'>
                    The Hebrew University, Reichman University, milab, Komodor, Vayyar, Navina, Elsc, Geox, Diagnostic Healthcare, Arrows, Otterize, Astrix, Tel Aviv Hall, AIPAC, Spark, and many more...
                </p>
            </div>

            <div className='header-height'></div>

                </div>
            </div>
        <div className='col-md col-xs-12'>
        </div>
            <div className='row mx-0 mb-5 p-0'>
                <p className='fs-18 fw-300'>Reach out</p>
                    <div className='col'>
                        <a className='fs-42 text-dark fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art</a>
                            </div>
                            <div className='col d-flex justify-content-end float-end'>
                                <a className='fs-42 text-dark fw-500' href="mailto:hello@fezzik.art">&nbsp;↗</a>
                        </div>
                    </div>
    </div>
</div>

     
  );
};

export default AboutPage;
