import React from 'react';
import Header from '../header/Header';
import { Link } from 'react-router-dom';

import arrowIcn from '../assets/icns/arrow-light.svg';
import arrowDarkIcn from '../assets/icns/arrow.svg';



const WebAnimationsPage = () => {
  return (
    <div className='row p-0 m-0'>
    <Header/>

      <div className='row px-4'>
        <div className='header-height'></div>
        <div className='col-lg-8 col-md-10 col-xs-12 px-4'>
            <div className='row px-md-4'>
            <div className='col-lg-12 col-md-10 col-sm-10 col-xs-12 mb-4'>
            <h4 className='fw-600 text-dark fs-60'>
            Marketing Animations:<br></br>Types and Examples
            </h4>
            </div>


            <div className='col-md-12 col-xs-12 mb-4'>
                <p className='fw-300 text-dark fs-24'>
                    According to the latest study, 28% of companies noticed that videos help build awareness, and 24% explained the benefits of products and services. For 18% of businesses, videos helped to increase sales. Also, 18% got more traffic by using videos in their marketing strategies.
                    For 49% of companies, videos serve as a tool to engage their audience. 52% confess that video helped build trust with prospective clients.
                    <br></br>
                    <br></br>

                    The numbers prove that a marketing animation video is a strong tool for attracting new clients and connecting with the existing ones. 
                </p>

                <div className='border-light-1px yellow-light-background d-inline-flex mt-3'>
                    <Link to="/works" className=''>
                        <p className='d-flex text-dark fs-22 fw-600 px-4 py-2 m-0'>
                            Our Works
                            <img className='ps-2' width="35px" src={arrowDarkIcn}/>
                        </p>
                    </Link>
                    </div>
            </div>



            <video
                  className='video'
                  src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/clariter_truck.mp4"
                  autoPlay
                  muted
                  loop
                  style={{ maxWidth: '100%' }}
                />



            <div className='col-md-12 col-xs-12 my-4 pt-4'>
                <h3 className='fs-28 fw-600'>
                        Explainer Video
                    </h3>

                <p className='mt-4 fw-300 text-dark fs-22'>
                An explainer video is the best option if you don’t know where to start with your video content. It will help you present the benefits of your product, explain to customers why they should use it, and demonstrate how they can solve their problems by becoming your clients.                </p>
           
                <Link to="/works/otterize" className=''>
                        <p className='d-flex text-dark fs-18 fw-600 p-0 m-0'>
                            Example
                            <img className='ps-2' width="35px" src={arrowDarkIcn}/>
                        </p>
                    </Link>
            </div>



            <div className='col-md-12 col-xs-12 my-4 pt-4'>
                <h3 className='fs-28 fw-600'>
                Thought Leadership Video
                                    </h3>

                <p className='mt-4 fw-300 text-dark fs-22'>
                Among video marketing animated videos, thought leadership videos are used on the stage of retention and advocacy. They help to build the relations with clients that you want: customers become brand advocates.                </p>
            

            </div>



            <div className='col-md-12 col-xs-12 my-4 pt-4'>
                <h3 className='fs-28 fw-600'>
                        Customer Testimonial
                    </h3>

                <p className='mt-4 fw-300 text-dark fs-22'>
                For 95% of customers, testimonials help to make a purchase decision. So what happens when you add a testimonial video to your website? You double up the sales!
                </p>
            </div>

          

            <div className='col-md-12 col-xs-12 my-4 pt-4'>
                <h3 className='fs-28 fw-600'>
                Case Study Video
                    </h3>

                <p className='mt-4 fw-300 text-dark fs-22'>
                What types of animated videos for marketing are better for service promotion? It is definitely a case study. 
                Case studies help to demonstrate how exactly your service helps to deal with the problem and what result it brings. You can create an animation or live-action video: it all depends on what aspects of your service you want to show.
                </p>

                <Link to="/works/navina" className=''>
                        <p className='d-flex text-dark fs-18 fw-600 p-0 m-0'>
                            Example
                            <img className='ps-2' width="35px" src={arrowDarkIcn}/>
                        </p>
                    </Link>
            </div>


            <div className='col-md-12 col-xs-12 my-4 pt-4'>
                <h3 className='fs-28 fw-600'>
                How-to Videos
                    </h3>

                <p className='mt-4 fw-300 text-dark fs-22'>
                Want to educate your users with videos? How-tos is what you need. You can create as many how-tos as you need depending on your video marketing plan.
            </p>


            <Link to="/works/navina" className=''>
                        <p className='d-flex text-dark fs-18 fw-600 p-0 m-0'>
                            Example
                            <img className='ps-2' width="35px" src={arrowDarkIcn}/>
                        </p>
                    </Link>
            </div>


            <div className='mt-5 '></div>
            <div className='green-background col-md-12 col-xs-12 px-4 py-4'>
            <Link to="/contact" className=''>
                <div className='d-inline-flex'>
                    <h3 className='fs-42 fw-700 text-light p-0 m-0'>
                        Schedule a call with us
                            </h3>
                                <img className=' float-end px-3' width="70px" src={arrowIcn}/>
                                    </div>

                    <p className='fw-300 text-light fs-18 p- m-0'>
                    We will be happy to help you with any video. Just give us a call, and let’s start the production!
                </p>
            </Link>
            
            </div>







            <div className='header-height'></div>

                </div>
            </div>

            <div className='col'>
                <video
                    className='video'
                    src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/contact_page.mp4"
                    loop
                    muted
                    autoPlay
                    style={{ maxWidth: '100%' }}
                />
            </div>
        </div>
                <div className='row mx-auto mb-5 px-4'>
                    <p className='fs-18 fw-300'>Reach out</p>
                    <div className='col'>
                        <a className='fs-42 text-dark fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art</a>
                            </div>
                            <div className='col d-flex justify-content-end float-end'>
                                <a className='fs-42 text-dark fw-500' href="mailto:hello@fezzik.art">&nbsp;↗</a>
                        </div>
                    </div>
    </div>

     
  );
};

export default WebAnimationsPage;
