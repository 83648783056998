import React, { useRef, useEffect } from 'react';
import Lottie from 'react-lottie';

import animationData1 from '../assets/imgs/navina/lotties/Hero_AN01.json';

import animationData2 from '../assets/imgs/navina/lotties/Charts/Charts_AN01.json';
import animationData3 from '../assets/imgs/navina/lotties/Charts/Charts_AN02.json';
import animationData4 from '../assets/imgs/navina/lotties/Charts/Charts_AN03.json';
import animationData5 from '../assets/imgs/navina/lotties/Charts/Charts_AN04.json';

import animationData6 from '../assets/imgs/navina/lotties/QA/QA_Comments_closeups.json';
import animationData7 from '../assets/imgs/navina/lotties/QA/QA_Graph.json';
import animationData8 from '../assets/imgs/navina/lotties/QA/QA_Status_change.json';

import animationData9 from '../assets/imgs/navina/lotties/Risk/Risk_Adjust_01.json';
import animationData10 from '../assets/imgs/navina/lotties/Risk/Risk_Adjust_02.json';
import animationData11 from '../assets/imgs/navina/lotties/Risk/Risk_Adjust_03.json';
import animationData12 from '../assets/imgs/navina/lotties/Risk/Risk_Adjust_04.json';

import animationData13 from '../assets/imgs/navina/lotties/Analysis/Analysis_01.json';
import animationData14 from '../assets/imgs/navina/lotties/Analysis/Analysis_02.json';
import animationData15 from '../assets/imgs/navina/lotties/Analysis/Analysis_03.json';
import animationData16 from '../assets/imgs/navina/lotties/Analysis/Analysis_04.json';

import animationData17 from '../assets/imgs/navina/lotties/Homepage/homepage01.json';
import animationData18 from '../assets/imgs/navina/lotties/Homepage/homepage02.json';
import animationData19 from '../assets/imgs/navina/lotties/Homepage/homepage03.json';
import animationData20 from '../assets/imgs/navina/lotties/Homepage/homepage04.json';



function LottieAnimation({ animationData }) {
  const containerRef = useRef(null);
  const lottieInstanceRef = useRef(null);


  const defaultOptions = {
    loop: true, 
    autoplay: false, 
    animationData: animationData,
    rendererSettings: {},
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            lottieInstanceRef.current.play();
          } else {
            lottieInstanceRef.current.stop();
          }
        });
      },
      { threshold: 0.5 } // Adjust this threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleClick = () => {

  };

  const getLottieInstance = (instance) => {
    lottieInstanceRef.current = instance;
  };


  return (
    <div className='col-9 my-5' ref={containerRef} onClick={handleClick}>
      <Lottie
        options={defaultOptions}
        ref={getLottieInstance}
      />
          </div>
);
}

function LottieFile() {


  return (
      <div className='row px-5 m-0'>
    <div className='col-9 my-5'>
          <p>Hero_AN01
          </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData1} />
          </div>
          <p>Charts_AN01:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData2} />
          </div>
          <p>Charts_AN02:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData3} />
          </div>
          <p>Charts_AN03:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData4} />
          </div>
          <p>Charts_AN04:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData5} />
          </div>
          <p>QA_Comments_closeups:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData6} />
          </div>
          <p>QA_Graph:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData7} />
          </div>
          <p>QA_Status_change:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData8} />
          </div>
          <p>Risk_Adjust_01:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData9} />
          </div>
          <p>Risk_Adjust_02:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData10} />
          </div>
          <p>Risk_Adjust_03:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData11} />
          </div>
          <p>Risk_Adjust_04:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData12} />
          </div>
          <p>Analysis_01:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData13} />
          </div>
          <p>Analysis_02:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData14} />
          </div>
          <p>Analysis_03:
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData15} />
          </div>
          <p>Analysis_04:
          </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData16} />
          </div>
          <p>homepage01:
            </p>
          <div className='mt-5 pt-5'>
          <LottieAnimation animationData={animationData17}  />
          </div>
          <p>homepage02:
            </p>
          <div className='mt-3'>
          <LottieAnimation animationData={animationData18} />
          </div>
          <p>homepage03:
            </p>
          <div className='mt-3'>
          <LottieAnimation animationData={animationData19} />
          </div>
          <p>homepage04:
            </p>
          <div className='mt-3'>
          <LottieAnimation animationData={animationData20}/>
          </div>
        </div>
        </div>
  );
}
  
  export default LottieFile;
  