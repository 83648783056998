import React from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';


import clariter02 from '../../assets/imgs/clariter/clariter02.png'; 
import clariter03 from '../../assets/imgs/clariter/clariter03.png'; 
import clariter04 from '../../assets/imgs/clariter/clariter04.png'; 
import clariter05 from '../../assets/imgs/clariter/clariter05.png'; 


const redirectToArrows = () => {
  window.location.href = '/works/arrows';
};

const redirectToVayyar = () => {
  window.location.href = '/works/vayyar';
};




const ClariterPage = () => {
  return (
    <div className='row p-0 m-0'>
    <Header/>
    <div className='header-height'></div>

    <div className='p-md-5'>
        <div className='row '>
          <div className='col-sm-1 col-xs-12'></div>
          <div className='col-sm-11 col-xs-12'>
          <div className='row'>
              <div className='col d-flex'>
                  <h4 className='fw-500 fs-28 capitalize'>Clariter</h4>
              </div>
                <div className='col d-flex float-end justify-content-end'>
                  <p className='fs-18 fw-300'></p>
                </div>

                </div>
                    <div className='col-12 mb-4 pb-2'>
                      <h4 className='fs-60 fw-400 capitalize'>
                        Video, Design and Brand materials to explain the clean slate solution of Clariter
                    </h4>
                </div>   
              
                <div className='col-12 p-2'>
                    <img
                      src={clariter02}
                      width='100%'
                    />
                </div>




          </div>
        </div> 



      <div className='row mt-2 pt-4'>

          <div className='row my-4 p-0'>
          <div className='col-6 p-2'>

          </div>
            <div className='col-6 p-2'>
                {/* <img
                  src={clariter03}
                  width='100%'
                /> */}
                <video
                  className='video'
                  src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/clariter_truck.mp4"
                  autoPlay
                  muted
                  loop
                  style={{ maxWidth: '100%' }}
                />
            </div>

            <div className='col-6 p-2'>
                <img
                  src={clariter04}
                  width='100%'
                />
            </div>
            <div className='col-6 p-2'>
                <img
                  src={clariter05}
                  width='100%'
                />
            </div>
          </div>


            
          </div>





          <div className='row mt-4 p-0'>
            <h4 className='capitalize mt-4'>This short animated explainer video was created to attract partnerships between Clariter and brand owners who want to be pioneers in the upcycling field.</h4>
            <div className='col-12 mt-4'>
              <video
                  className='video'
                  src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/Clariter.mp4"
                  controls
                  loop
                  style={{ maxWidth: '100%' }}
                />
            </div>
          </div>  



        </div>  


                <div className='row my-4 px-4'></div>
                  <div className='col-10 mx-auto py-4 '>
                      <p className='fs-18 fw-400'>This work includes</p>
                        <div className='row'>
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Capabilities</h4>
                              <ul className='p-0'>
                                <li className='py-1'>2D Animation</li>
                                <li className='py-1'>Illustration</li>
                                <li className='py-1'></li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Team</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Marketing Manager</li>
                                <li className='py-1'>Designer</li>
                                <li className='py-1'>Animator</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Cooperation</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Project</li>
  
                              </ul>
                          </div>

                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Industry</h4>
                                <li className='py-1'>Sustainability</li>
                                <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                                <li className='py-1'>1.5 Months</li>

                          </div>

                        </div>

                        <div className='py-3'></div>
    
                      </div>



        <div className='row px-sm-4 mx-auto '>
          <div className='border-top-dark-1px px-4'>
          </div>

              <div className='col d-flex pt-2'>
                  <p onClick={redirectToVayyar} className='fw-600 pointer'>Previous</p>
              </div>
              <div className='col pt-2 d-flex text-right justify-content-end '>
                  <p onClick={redirectToArrows} className='fw-600 pointer'>Next</p>
            </div>
        </div>  
            <div className=''>
                <Footer/>
            </div>      
    </div>
  );
};


export default ClariterPage;


