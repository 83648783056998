import React from 'react';
import Header from '../header/Header';

import partners from '../assets/imgs/partners.png';


const PartnersPage = () => {
  return (
    <div className='h-100'>
      <Header/>
            <div className='row align-items-center align-middle px-5 text-dark'>
            <div className='header-height m-sm-5'></div>

              <div className='col-md-9 col-sm-9 col-xs-12'>
                 <h3 className='fw-300 fs-24'>
                    Our Collaborations
                    </h3>

                    <p className='fw-400 fs-32 mt-3'>
                      Fezzik has partnered with leading Universities, organizations, VCs, and other programs to help their portfolio companies create professional videos.
                    </p>

                    <img className='img-fluid' src={partners} alt='navina-cover'/>


               </div>


            <div className='header-height'></div>


            <div className='col-md-9 col-sm-9 col-xs-12 border-bottom-dark-1px pb-5'>
              <div className='row'>

                <div className='col-md-12 col-sm-12 col-xs-12 mb-5'>
                    <h4 className='capitalize fs-42 fw-200'>
                       Your Portfolio companies gets more
                    </h4>
                </div>

                <div className='col-md col-sm col-xs-12 px-3'>
                  <h4>
                      Consulting 
                    </h4>
                      <p className='fs-18 fw-400'>
                        Free video strategy consulting
                      </p>
                </div>

                <div className='col-md col-sm col-xs-12 px-3'>
                  <h4>
                      High-end Videos
                    </h4>
                      <p className='fs-18 fw-400'>
                        Professional videos for demo day
                      </p>
                  </div>

                <div className='col-md col-sm col-xs-12 px-3'>
                  <h4>
                    Discounts
                  </h4>
                    <p className='fs-18 fw-400'>
                      Discounts for your portfolio companies
                    </p>
                </div>
                
              </div>
            </div>


           

            <div className=''>
            <div className='header-height m-5'></div>

              <div className='col-md-12 col-sm-12 col-xs-12 '>
                 <p className='fw-300 text-dark fs-32 capitalize'>
                    Wanna check how quick we response?
                  </p>
               </div>
               <div className='row'>
               <div className='col-md col-sm col-xs-12 my-3'>
                <p className='fs-18 m-0 p-0 fw-400'>Reach out</p>
                  <a className='fs-32 text-dark fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art&nbsp;↗</a>
                    <br></br>
                        <a className='fs-32 text-dark fw-500' href="https://api.whatsapp.com/send?phone=972528450488">Whatsapp&nbsp;↗</a>
               </div>
               <div className='col-md col-sm col-xs-12 my-3 '>
                <p className='fs-22 m-0 p-0 fw-400'>Socials</p>
                  <a className='fs-18 text-dark fw-200 border-bottom-dark-1px py-1 me-3' href="https://www.instagram.com/fezzikart/" target='blank'>Instagram</a>
                  <a className='fs-18 text-dark fw-200 border-bottom-dark-1px py-1 me-3' href="https://www.linkedin.com/company/fezzik-art/?viewAsMember=true" target='blank'>Linkedin</a>
                  <a className='fs-18 text-dark fw-200 border-bottom-dark-1px py-1 me-3' href="/" target='blank'>Vimeo</a>
                  <a className='fs-18 text-dark fw-200 border-bottom-dark-1px py-1' href="https://www.youtube.com/@fezzik_art" target='blank'>Youtube</a>


               </div>
            </div>
        </div>
    </div>


            </div>




  );
};

export default PartnersPage;
