import React, { useRef, useEffect } from 'react';
import './Header.css'

import replacement from '../assets/imgs/replacment.jpg';
import thirtyseconds from '../assets/imgs/fezzik/services_30sec.png';
import sixtyseconds from '../assets/imgs/fezzik/services_60sec.png';



const MenuServices = ({ }) => {
    const menuRef = useRef(null);

    const redirectToAnimation = () => {
        window.location.href = '/web-animation';
      };


    useEffect(() => {
    }, []);


    return (
        <div className="light-background overlay-menu w-50 z-3" ref={menuRef}>
            <div className='container-lg pb-4'>
            <div className='row px-4 mx-auto py-2'>
                <div className='col-12 pt-4' ref={menuRef}>
                    <div className='row'>
                        <div className='col-12'>
                            <h4 className='fw-400 fs-22 capitalize'>
                                We offer creative solutions to design, develop and present your brand's identity
                                    </h4>
                                        </div>
                                            </div>

                        <div className=''></div>
                        <div className='row'>
                            <div className='col-12'>
                                <h4 onClick={redirectToAnimation} className='menu fs-18 fw-300 pt-2 capitalize pointer'>
                                    Video Productions
                                </h4>
                                <h4 onClick={redirectToAnimation} className='menu fs-18 fw-300 pt-2 capitalize pointer'>
                                    Animation
                                </h4>
                                <h4 className='fs-18 fw-300 pt-2 capitalize'>
                                    programming
                                </h4>
                                <h4 className='fs-18 fw-300 pt-2 capitalize'>
                                    Product Design
                                </h4>
                                <h4 className='fs-18 fw-300 pt-2 capitalize'>
                                    Brand Design
                                </h4>
                                <h4 className='fs-18 fw-300 pt-2 capitalize'>
                                    Interactive Ideas
                                </h4>
                            </div>
                       
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default MenuServices;
