import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';



const ProposalPage = () => {
  return (
    <div className='light-background row container maxWidth-1920 mx-auto p-0 m-0'>
      <Header/>
          <div className='row align-items-center align-middle px-5'>
              <div className='header-height'></div>
              <div className='col-md-6 col-sm-7 col-xs-12 '>
                 <p className='fw-400 text-dark fs-60 mt-4'>
                    It's Time To Schedule Our First Date
                      </p>

                      <div className='row'>
                          <div className='col-md col-sm col-xs-12 mt-3'>
                            <p className='fs-18 m-0 p-0 fw-400'>Reach out</p>
                              <a className='fs-32 text-dark fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art&nbsp;↗</a>
                                <br></br>
                                    <a className='fs-32 text-dark fw-500' href="https://api.whatsapp.com/send?phone=972528450488">Whatsapp&nbsp;↗</a>
                          </div>
                          <div className='col-md col-sm col-xs-12 mt-3 '>
                            <p className='fs-22 m-0 p-0 fw-400'>Socials</p>
                              <a className='fs-18 text-dark fw-200 border-bottom-dark-1px py-1 me-3' href="https://www.instagram.com/fezzikart/" target='blank'>Instagram</a>
                              <a className='fs-18 text-dark fw-200 border-bottom-dark-1px py-1 me-3' href="https://www.linkedin.com/company/fezzik-art/?viewAsMember=true" target='blank'>Linkedin</a>
                              <a className='fs-18 text-dark fw-200 border-bottom-dark-1px py-1 me-3' href="/" target='blank'>Vimeo</a>
                              <a className='fs-18 text-dark fw-200 border-bottom-dark-1px py-1' href="https://www.youtube.com/@fezzik_art" target='blank'>Youtube</a>
                          </div>
                          </div>
               </div>
               <div className='col pt-4 d-flex justify-content-end'>
                  <video
                      className='video'
                      src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/contact_page.mp4"
                      loop
                      muted
                      autoPlay
                      style={{ maxWidth: '80%' }}
                    />
                </div>


            </div>
            <Footer/>
          </div>

  );
};

export default ProposalPage;
