import React from 'react';
import Header from '../header/Header';

import ARCH_img from '../assets/imgs/fezzik/arch-demo-services.png';


const ServicesPage = () => {
  return (  
    <div className='dark-background light-text'>
        <Header isDarkBackground={true}/>
        
        <div className='row m-0 py-md-5'>
        <div className='header-height m-3'></div>
        <div className='col-sm col-xs-12'>
        </div>
            <div className='col-sm-10 col-xs-12'>
                <div className='row py-5 px-5 align-content-center d-flex align-items-center'>
                    <div className='col-md-6 col-xs-12 pe-3'>
                        <h3 className='fs-18 my-3'>Explainer Videos</h3>
                            <p className='fw-300 fs-22'>
                            Make your audience quickly understand your value proposition and drive them to take action
                            </p>

                    </div>
                <div className='col-md-6 col-xs-12'>
                    <div className='row p-0 m-0'>
                        <video className='video-container' autoPlay muted 
                                src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/services-page-explainers.mp4"
                                />
                    </div>
                </div>
            </div>

        </div>
        </div>






<div className='row m-0 py-md-5'>
    <div className='col-sm col-xs-12'>
            </div>
            <div className='col-sm-10 col-xs-12'>
                <div className='row py-5 px-5 align-content-center d-flex align-items-center'>
                <div className='col-md-6 col-xs-12 pe-3 order-1'>
                <div className='row p-0 m-0'>
                        <video className='video-container' autoPlay muted 
                                src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/services-page-products.mp4"
                                />
                    </div>

                </div>

                <div className='col-md-6 col-xs-12 ps-md-5 order-md-1'>
                        <h3 className='fs-18 my-3'>Product Videos</h3>
                            <p className='fw-300 fs-22'>
                            Generate interest in your product by highlighting its standout features, whether it's a digital solution or a tangible item.
                            </p>
                    </div>

            </div>

        </div>

        </div>




        <div className='row m-0 py-md-5'>
        <div className='col-sm col-xs-12'>
        </div>
            <div className='col-sm-10 col-xs-12'>
                <div className='row py-5 px-5 align-content-center d-flex align-items-center'>
                    <div className='col-md-6 col-xs-12 pe-3'>
                    <h3 className='fs-18 my-3 fw-200'>Brand Videos</h3>
                    <p className='fw-400 fs-22'>
                    Bring your brand to life,
                    share your vision with the world,
                    and increase awareness
                    </p>
                    {/* <p className='mt-2 d-inline-flex fw-600 fs-14 pb-2 pe-4 pointer'>
                    Case Study
                    </p> */}

                    </div>
                <div className='col-md-6 col-xs-12'>
                    <div className='row p-0 m-0'>
                        <video className='video-container' autoPlay muted 
                                src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/services-page-brands.mp4"
                                />
                    </div>

                </div>
            </div>

        </div>
        </div>


        {/* <div className='row p-0 height-100px'>
            </div> */}



<div className='row m-0 py-md-5'>
    <div className='col-sm col-xs-12'>
            </div>
            <div className='col-sm-10 col-xs-12'>
                <div className='row py-5 px-5 align-content-center d-flex align-items-center'>
                <div className='col-md-6 col-xs-12 pe-3 order-1'>
                    <div className="video-container row">
                    <img className='img-fluid' src={ARCH_img} alt="" />

                        {/* <video className="blurred-video" autoPlay loop muted>
                            <source
                                src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/services-page-explainers.mp4"
                                type="video/mp4" />
                        </video> */}
                    </div>

                </div>

                <div className='col-md-6 col-xs-12 ps-md-5 order-md-1'>
                <h3 className='fs-18 my-3 fw-200'>ARCH CG Demos</h3>
                    <p className='fw-400 fs-22'>
                    Let your audience experience the architectural project with a 3D rendering video.
                                        </p>
                    {/* <p className='mt-2 d-inline-flex fw-600 fs-14 pb-2 pe-4 pointer'>
                    Case Study
                    </p> */}
                    </div>

            </div>

        </div>

        </div>





            <div className='row px-5 py-5 m-0 align-content-center'>
            <div className='col-md col-sm col-xs-12 my-3'>
                <p className='fs-22 m-0 p-0 fw-400'>Reach out</p>
                  <a className='fs-24 fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art&nbsp;↗</a>
                    <br></br>
                    <a className='fs-24 light-text fw-500' href="https://api.whatsapp.com/send?phone=972528450488">Whatsapp&nbsp;↗</a>
               </div>
               <div className='col-md col-sm col-xs-12 my-3 '>
                <p className='fs-22 m-0 p-0 fw-400 '>Socials</p>
                  <a className='fs-18 fw-200 border-bottom-dark-1px py-1 me-3' href="https://www.instagram.com/fezzikart/" target='blank'>Instagram</a>
                  <a className='fs-18 fw-200 border-bottom-dark-1px py-1 me-3' href="https://www.linkedin.com/company/fezzik-art/?viewAsMember=true" target='blank'>Linkedin</a>
                  <a className='fs-18 fw-200 border-bottom-dark-1px py-1 me-3' href="/" target='blank'>Vimeo</a>
                  <a className='fs-18 fw-200 border-bottom-dark-1px py-1' href="https://www.youtube.com/@fezzik_art" target='blank'>Youtube</a>


               </div>
            </div>

        </div>



     
  );
};

export default ServicesPage;
