import React, { useRef, useEffect } from 'react';
import Lottie from 'react-lottie';

import animationData1 from '../assets/imgs/ennocure/lottie/icon_01.json';
import animationData2 from '../assets/imgs/ennocure/lottie/icon_02.json';
import animationData3 from '../assets/imgs/ennocure/lottie/icon_03.json';

function LottieAnimation({ animationData }) {
  const containerRef = useRef(null);
  const lottieInstanceRef = useRef(null);


  const defaultOptions = {
    loop: true, 
    autoplay: false, 
    animationData: animationData,
    rendererSettings: {},
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            lottieInstanceRef.current.play();
          } else {
            lottieInstanceRef.current.stop();
          }
        });
      },
      { threshold: 0.5 } // Adjust this threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleClick = () => {

  };

  const getLottieInstance = (instance) => {
    lottieInstanceRef.current = instance;
  };


  return (
    <div className='col-9 my-5' ref={containerRef} onClick={handleClick}>
      <Lottie
        options={defaultOptions}
        ref={getLottieInstance}
      />
          </div>
);
}

function ReviewEnnocure() {


  return (
      <div className='row px-5 m-0'>
    <div className='col-9 my-5'>
          <p>Device Demo
          </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData1} />
          </div>
          <p>Patient View
            </p>
            should have a title and text explains that's the patient's side

          <div className='mt-3'>
            <LottieAnimation animationData={animationData2} />
          </div>
          <p>Doctor View<br>
          </br>
          should have a title and text explains that's the doctor's side
            </p>
          <div className='mt-3'>
            <LottieAnimation animationData={animationData3} />
          </div>
        </div>
        </div>
  );
}
  
  export default ReviewEnnocure;
  