import React from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';

import komodorBoothPlain from '../../assets/imgs/komodor/komodor-booth-plain.png'; 
import komodorBoothTurtleOne from '../../assets/imgs/komodor/komodor-turtle-one.png'; 
import komodorBoothTurtleTwo from '../../assets/imgs/komodor/komodor-turtle-two.png'; 
import komodorBoothTurtleThree from '../../assets/imgs/komodor/komodor-turtle-three.png'; 
import komodorBoothDemo from '../../assets/imgs/komodor/komodor-booth-demo.png'; 
import komodorIcons from '../../assets/imgs/komodor/komodor-turtles-icons.png'; 



const redirectToArrows = () => {
  window.location.href = '/works/arrows';
};

const redirectToVayyar = () => {
  window.location.href = '/works/vayyar';
};




const KomodorPage = () => {
  return (
  
    <div className='row p-0 m-0'>
    <Header/>
    <div className='header-height'></div>

    <div className='p-md-5'>
        <div className='row p-0 m-0 '>
          <div className='col-sm-1 col-xs-12'></div>
          <div className='col-sm-11 col-xs-12'>
          <div className='row'>
              <div className='col d-flex'>
                  <h4 className='fw-500 fs-28 capitalize'>Komodor</h4>
              </div>
                <div className='col d-flex float-end justify-content-end'>
                  <p className='fs-18 fw-300'>2023</p>
                </div>
             </div>
                <div className='col-12'>
                    <h4 className='fs-60 fw-600 capitalize'>
                      Making an interactive booth design for AWS conference
                    </h4>
                <img className='pt-5 img-fluid' src={komodorBoothPlain} alt=""/>
            </div>     
          </div>
        </div> 



          <div className='row px-5 py-5 m-0'>

          <div className='col-sm-1 col-xs-12 '>
          </div>

              <div className='col-sm-10 col-xs-12 py-5'>
                <div className='col-11'>
                  <p className='fs-22 fw-400'>
                  Presenting the company at technology conferences and events is a big challenge; you need to catch the eyes of the traffic audience while you are limited to your booth design. This year, Komodor's marketing team wanted to change their booth design and do something more creative than last year. Follow the wave of previous designs we made, adopting gaming, animation, and video games. This year, we decided to develop the idea of Komodor's K8S Mutant Turtles.
                  </p>
                </div>
 
                  <div className='py-3'></div>

                    <div className='col-12'>
                      <p className='fs-18 fw-400'>This work includes</p>
                        <div className='row'>
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Capabilities</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Booth Design</li>
                                <li className='py-1'>Animation</li>
                                <li className='py-1'>Product Design</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Team</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Product Manager</li>
                                <li className='py-1'>Marketing Manager</li>
                                <li className='py-1'>Designer</li>
                                <li className='py-1'>Animator</li>
                              </ul>
                          </div>
                          
                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Cooperation</h4>
                              <ul className='p-0'>
                                <li className='py-1'>Project</li>
  
                              </ul>
                          </div>

                          <div className='col'>
                            <h4 className='fs-18 fw-600'>Industry</h4>
                                <li className='py-1'>Tech</li>
                                <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                                <li className='py-1'>1 Months</li>

                          </div>

                        </div>

                        <div className='py-3'></div>
    
                        {/* <div className='col d-flex'>
                            <h4 className='fs-22 fw-600'>Reviews</h4>
                        </div> */}
                        <div className='my-3 '></div>

                      <div className='d-flex'>
                          <h4 className='fs-22 fw-700 dark-text'><a href='https://komodor.com' target='blank'>Visit Website &nbsp;↗</a> </h4>
                        </div>

                        </div>



                </div>

          </div>



          {/* <div className='row m-0 px-5'>
            <div className='col-12'>
              <video
                  className='video'
                  src="https://paz-roth-videos.s3.eu-west-3.amazonaws.com/navina_mockups0001.mp4"
                  autoPlay
                  muted
                  loop
                  style={{ maxWidth: '100%' }}
                />
            </div>
          </div> */}


          <div className='row px-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
              <h4 className='fw-600 fs-22 pb-3'>Approach</h4>
                <p className='fs-22 fw-400'>
                  To achieve the idea, we took the main symbol of Komodor branding, the bot's icon, and transferred it into the look of a ninja turtle. To refer the materials to the look and feel of comics we designed everything in Pop Art style, using a dominant colors, black contours and shadow made from dark dots.
                  </p>
            </div>
          </div>



          <div className='row m-0 px-5'>
            <div className='col p-2'>
                <img
                  src={komodorBoothTurtleOne}
                  width='100%'
                />
            </div>
            <div className='col p-2'>
                <img
                  src={komodorBoothTurtleTwo}
                  width='100%'
                />
            </div>
            <div className='col p-2'>
                <img
                  src={komodorBoothTurtleThree}
                  width='100%'
                />
            </div>
          </div>
 


          <div className='row px-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
                <p className='fs-22 fw-400'>
                The guideline for the first conference was to design two parts; we wanted to add a floor design and continue the wall into the stand as one unit, but the offer was refused due to limitations in the conference. Due to that, we wanted to create a connection between the wall and the stand with a similar design that creates a continuity between them despite the gap they have. Our goal was to create the feeling of standing in 'a cool' and 'young' company when people visit the Komodor's booth, and by that, to get them curious about the product and its connection to the Ninja warriors.
                </p>
            </div>
          </div>



          {/* <div className='row px-sm-5 py-5 m-0'>
            <div className='col-12 px-5 py-5'>
              <h4 className='fw-600 fs-22 pb-3 opacity-50'>Results</h4>
              <h4 className='fs-42 fw-600'>Booth Traffic</h4>


              <div className='ro border-bottom-dark-1px'>
                  <div className='col-sm-11 col-xs-12 container text-center capitalize'>
                      <div className='row px-5 py-5'>
                        <div className='col-md-4 col-sm-12 p-4'>
                            <h4 className='fs-60 fw-700'></h4>
                              <p className='fs-24 fw-400 '>more engagments</p>
                          </div>
                          <div className='col-md-4 col-sm-12 p-4'>
                            <h4 className='fs-60 fw-700'>50%</h4>
                              <p className='fs-24 fw-400 '>increase in website time view</p>
                          </div>
                          <div className='col-md-4 col-sm-12 p-4'>
                            <h4 className='fs-60 fw-700'>55%</h4>
                              <p className='fs-24 fw-400 '>growth in leads</p>
                          </div>

                      </div>
                    </div>
                  </div>
              </div>
          </div> */}


          {/* <div className='row px-sm-5 pb-5 m-0'>
              <div className='col-12 px-5 pb-5'>
                  <p className='fs-22 fw-200'>

                  </p>

                  <div className='d-flex pt-4'>
                    <img
                    src={reviewerImg}
                    width='60px'
                    height='60px'
                    />
                    <p className='fs-18 fw-300 ms-3'>Niki Green<br></br>
                      <span className='fw-500 fs-16'>Head of Marketing</span></p>
                  </div>
              </div>
          </div> */}



          <div className='row px-sm-5 m-0'>
              <div className='col d-flex'>
                  <p onClick={redirectToVayyar} className='fw-600 pointer'>Previous</p>
              </div>
              <div className='col d-flex text-right justify-content-end '>
                  <p onClick={redirectToArrows} className='fw-600 pointer'>Next</p>
            </div>
        </div>  
            <div className='row pb-5 px-sm-5 m-0'>
                <Footer/>
            </div>
        </div>        
    </div>

  );
};


export default KomodorPage;


