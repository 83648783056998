import React, { useState, useEffect } from 'react';
import lottie from 'lottie-web';
import Header from '../header/Header';

import animationData from '../assets/videos/loading_animation.json';



const IntroPage = () => {
  const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const animation = lottie.loadAnimation({
//         container: document.getElementById('loadingAnimation'),
//         renderer: 'svg',
//         loop: false,
//         autoplay: true,
//         animationData: animationData // Pass your animation data
//     });

//     animation.addEventListener('complete', () => {
//         setLoading(false); 
//         redirectToWorks();
//     });

//     return () => {
//         animation.destroy();
//     };
// }, []);



  // const redirectToWorks = () => {
  //   window.location.href = '/';
  // };

  return (

    <div className='dark-background h-100'>
      <Header isDarkBackground={true}/>

      <div className='row px-5 py-2 m-0'>
        <div className='header-height mt-5'></div>
        <div className='border h-80'>
          <p>adsdad</p>



        </div>
      </div>


      {/* {!loading && (
        <div className='h-100 dark-background'>
        </div>

      )}

      {loading && (
         <div id="loadingAnimation">
            </div>
            )} */}
      
  </div>

  );
};


export default IntroPage;