import React from 'react';
import Header from '../header/Header';
import otterize from '../assets/imgs/otterize/cover-img.png';
import astrix from '../assets/imgs/astrix/cover-img.png';
import arrows from '../assets/imgs/arrows/cover-img.png';
import navina from '../assets/imgs/navina/navina00.png';
import spark from '../assets/imgs/spark/cover-img.png';
import turtles from '../assets/imgs/komodor/cover-img.png';
import geox from '../assets/imgs/covers/geox.png';
import vayyar from '../assets/imgs/vayyar/cover-img.png';
import clariter from '../assets/imgs/clariter/clariter00.png';
import ennocure from '../assets/imgs/ennocure/cover-img.png';
import monet from '../assets/imgs/monet/cover-img.png';





const Artworks = () => {

    const redirectToOtterize = () => {
        window.location.href = '/works/otterize';
      };

      const redirectToAstrix = () => {
        window.location.href = '/works/astrix';
      };
      
      const redirectToNavina = () => {
        window.location.href = '/works/navina';
      };
      
      const redirectToKomodor = () => {
        window.location.href = '/works/komodor';
      };
      
      const redirectToArrows = () => {
        window.location.href = '/works/arrows';
      };
      
      const redirectToSpark = () => {
        window.location.href = '/works/spark';
      };
      
      const redirectToGeox = () => {
        window.location.href = '/works/geox';
      };
      
      const redirectToVayyar = () => {
        window.location.href = '/works/vayyar';
      };

      const redirectToDhc = () => {
        window.location.href = '/works/dhc';
      };

      const redirectToClariter = () => {
        window.location.href = '/works/clariter';
      };
      
      const redirectToEnnocure = () => {
        window.location.href = '/works/ennocure';
      };
      
      
      
  return (
    <div className='light-background row p-0 m-0'>
      <Header isDarkBackground={false}/>
        <div className='header-height'></div>
            <div className=''>
            <div className='row p-0 m-0 '>
                <div className='width-250px'></div>
                <div className='col-lg col-md-12 col-sm-12 px-3'>
                    
                <div className='row p-0 m-0'>
                    <div className="d-inline-flex col-sm-12 col-xs-12 mt-sm-4">
                        <h4 className='fw-500 fs-42' style={{ fontSize: window.innerWidth <= 900 ? '28px' : '60px' }}>We are the creative opportunity to bring your brand's stories, products, and user experience to life through art and technology</h4>
                    </div>
                </div>
            
        <div className='row'>
            <div className='w-100 my-4'></div>
            {/* <div className='col-sm-6 col-xs-12 p-3'>
                <div onClick={redirectToNavina} className='image-container pointer' >
                <img className='img-fluid' src={navina} alt='navina-cover' width={1920} height={1080}/>
                </div>
                <div className='my-2 text-dark'>
                    <h5 onClick={redirectToNavina} className='fs-18 fw-500 capitalize pointer'>Navina | Web Animations 24</h5>

                </div>
            </div>
            <div className='col-sm-6 col-xs-12 p-3'>
                <div onClick={redirectToNavina} className='image-container pointer' >
                <img className='img-fluid' src={navina} alt='navina-cover' width={1920} height={1080}/>
                </div>
                <div className='my-2 text-dark'>
                    <h5 onClick={redirectToNavina} className='fs-18 fw-500 capitalize pointer'>Navina | Web Animations 24</h5>

                </div>
            </div> */}
            <div className='col-sm-6 col-xs-12 p-3'>
                <div className='image-container ' >
                <img className='img-fluid' src={monet} alt='monet-cover' width={1920} height={1080}/>
                </div>
                <div className='my-2 text-dark'>
                    <h5 className='fs-18 fw-500 capitalize '>Monet | <span className='fw-700'>Coming Soon</span> </h5>
                </div>
            </div>
            <div className='col-sm-6 col-xs-12 p-3'>
                    <div onClick={redirectToNavina} className='image-container pointer' >
                    <img className='img-fluid' src={navina} alt='navina-cover' width={1920} height={1080}/>
                    </div>
                    <div className='my-2 text-dark'>
                        <h5 onClick={redirectToNavina} className='fs-18 fw-500 capitalize pointer'>Navina | Web Animations 24</h5>
                    </div>
                </div>
                <div className='col-sm-6 col-xs-12 p-3'>
                    <div onClick={redirectToAstrix} className='image-container pointer' >
                    <img className='img-fluid' src={astrix} alt='astrix-cover' width={1920} height={1080}/>
                    </div>
                    <div className='my-2 text-dark'>
                        <h5 onClick={redirectToAstrix} className='fs-18 fw-500 capitalize pointer'>Astrix Security | Explainer Video</h5>
                    </div>
                </div>
                <div className='col-sm-6 col-xs-12 p-3'>
                <div onClick={redirectToOtterize} className='image-container pointer' >
                    <img className='img-fluid' src={otterize} alt='otterize-cover' width={1920} height={1080}/>
                    </div>
                    <div className='my-2 text-dark'>
                        <h5 onClick={redirectToOtterize} className='fs-18 fw-500 capitalize pointer'>Otterize | Explainer Video 24 </h5>
                    </div>
                </div>
                <div className='col-sm-6 col-xs-12 p-3'>
                    <div onClick={redirectToClariter} className='image-container pointer' >
                    <img className='img-fluid' src={clariter} alt='navina-cover' width={1920} height={1080}/>
                    </div>
                    <div className='my-2 text-dark'>
                        <h5 onClick={redirectToClariter} className='fs-18 fw-500 capitalize pointer'>Clariter | Animated Explainer Video</h5>
                    </div>
                </div>
                <div className='col-sm-6 col-xs-12 p-3'>
                    <div onClick={redirectToArrows} className='image-container pointer' >
                    <img className='img-fluid' src={arrows} alt='arrows-cover' width={1920} height={1080}/>
                    </div>
                    <div className='my-2 text-dark'>
                        <h5 onClick={redirectToArrows} className='fs-18 fw-500 capitalize pointer'>Arrows | Avatar Interaction</h5>
                    </div>
                </div>
                <div className='col-sm-6 col-xs-12 p-3'>
                    <div onClick={redirectToVayyar} className='image-container pointer' >
                    <img className='img-fluid' src={vayyar} alt='navina-cover' width={1920} height={1080}/>
                    </div>
                    <div className='my-2 text-dark'>
                        <h5 onClick={redirectToVayyar} className='fs-18 fw-500 capitalize pointer'>Vayyar | App Interaction</h5>

                    </div>
                </div>
                <div className='col-sm-6 col-xs-12 p-3'>
                    <div onClick={redirectToEnnocure} className='image-container pointer' >
                    <img className='img-fluid' src={ennocure} alt='astrix-cover' width={1920} height={1080} />
                    </div>
                    <div className='my-2 text-dark'>
                        <h5 onClick={redirectToEnnocure} className='fs-18 fw-500 capitalize pointer'>Ennocure | Product Animation </h5>
                    </div>
                </div>          
                <div className='col-sm-6 col-xs-12 p-3'>
                    <div onClick={redirectToKomodor}  className='image-container pointer' >
                    
                    <img className='img-fluid' src={turtles} alt='navina-cover' width={1920} height={1080}/>
                    </div>
                    <div className='my-2 text-dark'>
                        <h5 onClick={redirectToKomodor} className='fs-18 fw-500 capitalize pointer'>Komodor | Interactive Booth</h5>
                    </div>
                </div>

                <div className='col-sm-6 col-xs-12 p-3'>
                    <div onClick={redirectToSpark} className='image-container pointer' >
                    <img className='img-fluid' src={spark} alt='arrows-cover' width={1920} height={1080}/>
                    </div>
                    <div className='my-2 text-dark'>
                        <h5 onClick={redirectToSpark} className='fs-18 fw-500 capitalize pointer'>Spark | 2D Explainer Video</h5>
                    </div>
                </div>
              
                <div className='col-sm-6 col-xs-12 p-3'>
                    <div onClick={redirectToGeox} className='image-container pointer' >
                    <img className='img-fluid' src={geox} alt='navina-cover' width={1920} height={1080}/>
                    </div>
                    <div className='my-2 text-dark'>
                        <h5 onClick={redirectToGeox} className='fs-18 fw-500 capitalize pointer'>Geox | Animated Explainer</h5>
                    </div>
                </div>

                {/* <div className='col-sm-6 col-xs-12 p-3'>
                    <div onClick={redirectToDhc} className='image-container pointer' >
                    <img className='img-fluid' src={dhc} alt='navina-cover' width={1920} height={1080}/>
                    </div>
                    <div className='my-2 text-dark'>
                        <h5 onClick={redirectToDhc} className='fs-18 fw-300 capitalize pointer'>DHC Brand Reshape</h5>
                        <p className='fs-16 fw-300'>Graphic materials for upgrading the look and feel  </p>
                    </div>
                </div> */}


                {/* <div className='col-sm-6 col-xs-12 p-3'>
                    <div onClick={redirectToClariter} className='image-container pointer' >
                    <img className='img-fluid' src={replacment} alt='navina-cover' width={1920} height={1080}/>
                    </div>
                    <div className='my-2 text-dark'>
                        <h5 onClick={redirectToClariter} className='fs-18 fw-300 capitalize pointer'>Charcater</h5>
                        <p className='fs-14 fw-300'>Series of UI product animation  </p>
                    </div>
                </div> */}
            </div>
        </div>
    </div>

      
    <div className='header-height m-3'></div>
      <div className='row mx-3 mb-5 p-0'>
          <p className='fs-18 fw-300'>Reach out</p>
          <div className='col'>
                <a className='fs-42 text-dark fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art</a>
              </div>
              <div className='col d-flex justify-content-end float-end'>
                <a className='fs-42 text-dark fw-500' href="mailto:hello@fezzik.art">&nbsp;↗</a>
              </div>
        </div>
      </div>
    </div>
  );
};

export default Artworks;
