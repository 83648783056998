import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoLight from './logo-light.svg';
import logoDark from './logo-dark.svg';
import whatsapp from '../assets/icns/whatsapp-icn.svg';

// import menuDark from '../assets/icns/menu-dark.svg';
// import menuLight from '../assets/icns/menu-light.svg';

import './Header.css'
import Menu from './Menu';
import MenuServices from './ServicesMenu';
import MenuWorks from './WorksMenu';

const HeaderDev = ({ isDarkBackground }) => {

  const logoSrc = isDarkBackground ? logoLight : logoDark ;

  // const menuIcn = isDarkBackground ? menuLight : menuDark ;


  const redirectToProposal = () => {
    window.location.href = '/contact';
  };

  const redirectToWorks = () => {
    window.location.href = '/works';
  };

  const redirectToDev = () => {
    window.location.href = '/development';
  };

  const redirectToAbout = () => {
    window.location.href = '/about';
  };



    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMenuServicesOpen, setIsMenuServicesOpen] = useState(false);
    const [isMenuWorksOpen, setisMenuWorksOpen] = useState(true);

    

    // const openMenu = () => {
    //     setIsMenuOpen(true);
    //     document.body.style.overflow = 'hidden'; 
    // };

    // const closeMenu = () => {
    //   setIsMenuOpen(false);
    //   document.body.style.overflow = 'auto'; 
    // };

    const openServicesMenu = () => {
      setIsMenuServicesOpen(true);
      document.body.style.overflow = 'hidden';
    };

    const closeServicesMenu = () => {
      setIsMenuServicesOpen(false);
      document.body.style.overflow = 'auto';

    };



    const openWorksMenu = () => {
      setisMenuWorksOpen(true);
      document.body.style.overflow = 'hidden';
    };

    const closeWorksMenu = () => {
      setisMenuWorksOpen(false);
      document.body.style.overflow = 'auto';

    };





  return (
    <div className='header mx-auto'>
      <div className='row'>
        <div className='col-3'>
            <Link to="/" className=''>
                  <img className='logo' src={logoSrc} alt="Logo"/>
            </Link>
        </div>

        <div className='col d-inline-flex justify-content-end mt-2'>
        {/* <div onClick={redirectToDev} 
            style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}
                 className='menu'>
                <p className='fs-16 fw-400 pointer m-1 '>Development</p>
            </div>
            <span className='mx-2 m-1 large_menu'
              style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}
            ></span> */}
            <div className='d-inline-flex menu'
              onMouseOver={openServicesMenu} onMouseLeave={closeServicesMenu}
              style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}>
              <p className='fs-16 fw-400 pointer m-1 '>
                  Services
                  {/* <svg className='mx-3 mt-1' xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
                <path d="M11.52.27c.37-.37.96-.37,1.33,0s.37.96,0,1.33l-5.62,5.62c-.37.37-.96.37-1.33,0,0,0,0,0,0,0L.27,1.6C-.09,1.24-.09.64.27.27S1.24-.09,1.6.27l4.93,4.93L11.52.27Z"/>
                </svg> */}

                </p>
             
            {isMenuServicesOpen && <MenuServices/>}
          </div>

            <span className='mx-2 m-1 large_menu'
              style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}
            ></span>
            <div className='d-inline-flex menu'
              onMouseOver={openWorksMenu} onMouseLeave={closeWorksMenu} onClick={redirectToWorks}
              style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}>
              <p className='fs-16 fw-400 pointer m-1 '>
                  Works
                  {/* <svg className='mx-3 mt-1' xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
                <path d="M11.52.27c.37-.37.96-.37,1.33,0s.37.96,0,1.33l-5.62,5.62c-.37.37-.96.37-1.33,0,0,0,0,0,0,0L.27,1.6C-.09,1.24-.09.64.27.27S1.24-.09,1.6.27l4.93,4.93L11.52.27Z"/>
                </svg> */}

                </p>
             
            {/* {isMenuWorksOpen && <MenuWorks/>} */}
          </div>

            <span className='mx-2 m-1 large_menu'
              style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}
            ></span>
            {/* <div onClick={redirectToWorks} 
            style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}
                 className=''>
                <p className='fs-14 fw-300 pointer m-1 '>Pricing</p>
            </div>
            <span className='mx-2 m-1 large_menu'
              style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}
            ></span> */}
            <div onClick={redirectToAbout} 
            style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}
                 className='menu'>
                <p className='fs-16 fw-400 pointer m-1 '>About</p>
            </div>
            <span className='mx-2 m-1 large_menu'
              style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}
            ></span>
            
            <div style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}
                onClick={redirectToProposal} className='menu'>
                <p className='fs-16 fw-600 pointer m-1'>Contact</p>
            </div>
            <span className='mx-2 m-1 large_menu'
              style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}
            ></span>
            
            <div style={{ color: isDarkBackground ? '#CFDCEC' : '#0c0c0c' }}
               className='header-menu'>
                <a href="https://api.whatsapp.com/send?phone=972528450488" className='fs-16 fw-400 pointer m-1'>
                  <img width={'30px'} src={whatsapp}/>
                </a>
            </div>

            {/* <div onClick={isMenuOpen ? closeMenu : openMenu}>
              <img className='menu-icn pointer mt-1' src={menuIcn} alt=""/>
            </div> */}
        </div>


        </div>
                {isMenuOpen && <Menu isDarkBackground={true} setIsMenuOpen={setIsMenuOpen} />}

        </div>

      );
    }
  
  export default HeaderDev;
  