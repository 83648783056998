import React from 'react';
import LinkedinIcn from '../assets/icns/linkedin-icn.svg';
import InstagramIcn from '../assets/icns/instagram-icn.svg';



const Footer = () => {

    const redirectToProposal = () => {
        window.location.href = '/proposal';
      };
    

    return (
        <div className='row pt-4 mt-4'>
            <div className='d-flex mt-4'>
            <div className='col'>
                <p className='fs-16 text-dark fw-200'>© Fezzik | anmetz ltd 2024</p>
                </div>
            <div className=' justify-content-end '>
                    <a className='fs-16 text-dark fw-500 ps-4' href="/sitemap">&nbsp;Sitemap</a>
                    <a className='fs-16 text-dark fw-500 ps-4' href="mailto:hello@fezzik.art">Email</a>
                    <a className='fs-16 text-dark fw-500 ps-4' href="https://www.linkedin.com/company/fezzik-art/?viewAsMember=true"><img width={'30px'} src={LinkedinIcn}/></a>
                    <a className='fs-16 text-dark fw-500 ps-4' href="https://www.instagram.com/fezzikart/"><img width={'30px'} src={InstagramIcn}/></a>
                        </div>
                            </div>
                            </div>
    );
}

export default Footer;
