import React, { useEffect, useState } from 'react';
import './Playground.css';
import { Unity, useUnityContext } from "react-unity-webgl";
import Header from '../header/Header';

const PlaygroundPage = () => {

  const [is_loading, set_is_loading] = useState(true);

  useEffect(() => {
      const timer = setTimeout(() => {
        set_is_loading(false);
      }, 5000); 
      return () => clearTimeout(timer);
    }, []);


    const { unityProvider, sendMessage } = useUnityContext({
      loaderUrl: "https://fezzik-website.s3.eu-south-1.amazonaws.com/bimai.loader.js",
      dataUrl: "https://fezzik-website.s3.eu-south-1.amazonaws.com/bimai.data",
      frameworkUrl: "https://fezzik-website.s3.eu-south-1.amazonaws.com/bimai.framework.js",
      codeUrl: "https://fezzik-website.s3.eu-south-1.amazonaws.com/bimai.wasm",
    });


    // const { unityProvider, sendMessage } = useUnityContext({
    //   loaderUrl: "build/bimai.loader.js",
    //   dataUrl: "build/bimai.data",
    //   frameworkUrl: "build/bimai.framework.js",
    //   codeUrl: "build/bimai.wasm",
    // });

    function handle_pose_change(pose){
        if (unityProvider) {
          console.log(pose);
          sendMessage("animation_ctrl", "set_pose", pose)
        }
      }


  return (
    <div className='light-background h-100'>
    <Header/>

    <div className='row px-5'>
    <div className='header-height m-sm-5'></div>

      <div className='col-md col-xs-12'>
          {/* <div className='row'>
              <div className='col-6 d-flex'>
                <h4 className='fw-400 fs-24'>Astrix Security Explained</h4>
              </div>

              <div className='col d-flex float-end justify-content-end'>
                <p className='fs-18 fw-300'>2022</p>
                </div>
    
                <div className='col-md-10 col-xs-12'>
                    <p className='fs-18 fw-300'>
                    We teamed up with Astrix to create a short animated movie explaining Astrix's platform coming to solve. We build the script to cover three sections, describing the situation, an overview of the problem, and a short teaser of the solution.                </p>
                </div>
            </div> */}



                  <div className='dark_background'>
                    <div className='row light_text '>

                    <div className='col-sm-12 dark_background'>
                        <p style={{opacity: is_loading ? '100' : '0'}}>Loading...</p>
                        <Unity 
                          style={{opacity: is_loading ? '0' : '100'}}
                          unityProvider={unityProvider}
                          className='h-50 w-100'
                          />
                      </div>


                    <div className='col-sm'>
                        <div className='d-flex'>
                        {/* <div className='pe-5'>
                            <p className='pointer fs-20 fw-600' onClick={() => handle_pose_change("alive")}>
                              Stop
                            </p>
                          </div> */}
                          <div className='pe-5'>
                            <p className='pointer fs-20 fw-500' onClick={() => handle_pose_change("hello")}>
                              Hello
                            </p>
                          </div>
                          <div className='pe-5'>
                          <p className='pointer fs-20 fw-500' onClick={() => handle_pose_change("walking")}>
                              Walking
                            </p>
                          </div>
                          <div className='pe-5'>
                          <p className='pointer fs-20 fw-500' onClick={() => handle_pose_change("walking_fast")}>
                              Walking Fast
                            </p>
                          </div>
                          <div className='pe-5'>
                          <p className='pointer fs-20 fw-500' onClick={() => handle_pose_change("running")}>
                              Running
                            </p>
                          </div>


                          {/* <div className='col-12'>
                          <p className='pointer fs-20 fw-400' onClick={() => handle_pose_change("hello")}>
                              super hero
                            </p>
                          </div>
                          <div className='col-12'>
                          <p className='pointer fs-20 fw-400' onClick={() => handle_pose_change("hello")}>
                              look to directions
                            </p>
                          </div>

                          <div className='my-5 border_bottom_light_1px'>
                          </div>

                          <div className=''>
                              <p className='fw-600 fs-24'>
                                Look Direction
                              </p>
                              <div className='d-inline-flex'>
                                <button className="xbox-button up"></button>
                                <button className="xbox-button left"></button>
                                <button className="xbox-button right"></button>
                                <button className="xbox-button down"></button>
                              </div>
                          </div> */}
                        </div>
                    </div>       
                </div>
            </div>


      </div>
    </div>
  </div>
  );
};


export default PlaygroundPage;


