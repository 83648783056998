import React from 'react';
import Header from '../header/Header';

import replacment from '../assets/imgs/replacment.jpg';



const VideoB2BPage = () => {
  return (
    <div className='light-background row container maxWidth-1920 mx-auto'>
      <Header/>

      <div className='row px-4'>
        <div className='col-md-10 col-sm-12'>
            <div className='col-lg-7 col-md-8 col-sm-12'>
                <p className='fw-100 text-dark fs-42 fw-400 capitalize'>
                    B2B Explainer Video for Your Partner or Investor
                </p>
            </div>

        <div className='row'>
            <div className='col'>
                    <p className='fw-200 text-dark fs-22'>
                    Businesses worldwide employ animated explainer videos to convey their services, products, and concepts. The B2B sector is no different. Whether you’re presenting your business to customers, investors, or partners, animation can transform your pitch into an engaging narrative that turns viewers into potential leads.
                                        </p>
                    <p className='fw-500 text-dark fs-22'>
                        Get info & Pricing
                    </p>
                </div>
                <div className='col'>
                    <p className='fw-200 text-dark fs-22'>
                    With extensive experience in creating animated videos for B2B businesses around the globe, Explain Ninja provides comprehensive video production services designed to help your company dominate the market competition.                    </p>
                </div>
            </div>
 
        
        <div className='py-4'></div>


        <div className='row mt-4 pt-4'>
            <div className='col-6'>
                <h4 className='text-dark fs-24 fw-400'>
                    Deliver a clear and powerful message
                </h4>
                <p className='text-dark fs-18 fw-200'>
                    B2B explainer videos enable brands to communicate their concepts to customers much more efficiently. Engaging animation allows delivering a brand message concisely and engagingly. It helps your brand to sound loud and be heard by potential customers in the digital space.
                </p>
            </div>
            <div className='col-6'>
                <img className='img01' width={'100%'} src={replacment} alt="Logo"/>
            </div>
        </div>

        <div className='row mt-4 pt-4'>
            <div className='col-6'>
                <img className='img01' width={'100%'} src={replacment} alt="Logo"/>
            </div>
            <div className='col-6'>
                <h4 className='text-dark fs-24 fw-400'>
                    Build a memorable brand image
                </h4>
                <p className='text-dark fs-18 fw-200'>
                    Are you looking for innovative ways to build your brand image in the online world? Using an animated logo, powerful brand message, and branded colors in a creative explainer video, you can increase audience engagement and brand awareness.
                </p>
            </div>
        </div>

        <div className='row mt-4 pt-4'>
            <div className='col-6'>
                <h4 className='text-dark fs-24 fw-400'>
                    Introduce your business concept creatively
                </h4>
                <p className='text-dark fs-18 fw-200'>
                    Businesses across different industries are struggling for the target audience’s attention. The market game is becoming even more challenging. A custom animated explainer video can help organizations shape a distinguishing brand voice and outperform competitors with an original presentation of business concepts.
                </p>
            </div>
            <div className='col-6'>
                <img className='img01' width={'100%'} src={replacment} alt="Logo"/>
            </div>
        </div>


    <div className='py-4'></div>


        <div className='col-lg-7 col-md-10 col-sm-12 pb-2 pt-4'>
            <p className='fw-100 text-dark fs-42 fw-400 capitalize'>
                We can produce for you
            </p>
        </div>

        <div className='row mt-4'>
            <div className='col-6 pe-4 mb-4'>
                <h4 className='text-dark fs-18 fw-400'>
                    2D Animation
                </h4>
                <p className='text-dark fs-18 fw-100'>
                    2D animation is the art of creating the illusion of motion in a two-dimensional environment by sequencing individual drawings together over time. This animation style is widely used in the production of cartoons, advertisements, marketing videos, and tradeshow introductory videos.
                </p>
            </div>
            <div className='col-6 pe-4 mb-4'>
                <h4 className='text-dark fs-18 fw-400'>
                    3D Animation
                </h4>
                <p className='text-dark fs-18 fw-100'>
                    3D animation is a process of using motion to bring heroes, objects, vehicles, and props to life in a digital three-dimensional space. 3D videos give much more freedom to the imagination and allow creating objects that can turn around like in real-life and look more natural.
                </p>
            </div>
            <div className='col-6 pe-4 mb-4'>
                <h4 className='text-dark fs-18 fw-400'>
                    Screencast
                </h4>
                <p className='text-dark fs-18 fw-100'>
                    We offer screencast production with a persuasive script and powerful voiceover. A screencast is a video-recording of a digital product user experience and screen output. Screencast videos are widely used in mobile app marketing, digital tool promotion, and software product advertising.
                </p>
            </div>
            <div className='col-6 pe-4 mb-4'>
                <h4 className='text-dark fs-18 fw-400'>
                    Stop Motion
                </h4>
                <p className='text-dark fs-18 fw-100'>
                    Stop motion is an animation technique in which objects are moved manually in small increments between individually photographed frames. Merging these frames creates the illusion of independent motion in the space. Stop motion helps present your ideas innovatively.
                </p>
            </div>
        </div>


    </div>

        <div className='row py-4 mb-4'>
            <p className='fs-18 fw-300'>Reach out</p>
                <div className='col-12 m-0'>
                    <a className='fs-42 text-dark fw-500' href="mailto:hello@fezzik.art">hello@fezzik.art</a>
                        <div className='col d-flex justify-content-end float-end'>
                            <a className='fs-42 text-dark fw-500' href="mailto:hello@fezzik.art">&nbsp;↗</a>
                    </div>
                </div>
            </div>

    </div>








    </div>
  );
};

export default VideoB2BPage;
