import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';





const AvatarsPage = () => {



  return (
    <div className='dark-background row container maxWidth-1920 mx-auto p-0 m-0'>
      <Header isDarkBackground={true} />

        <div className='p-5'></div>

            <div className='row mb-5 mt-1'>
                <div className='col-9 px-5'>
                    <h4 className='fw-600 text-light fs-60 capitalize'>
                        Make you support chat more natural with your costumized avatar
                    </h4>
                </div>            
            </div>            




            <div className='header-height py-5'></div>


            <div className='row px-5 m-0'>
                <div className='col-sm-6 col-xs-12 pe-5'>
                    <h4 className='fs-60 fw-600 capitalize'>
                        Works We’ve Brought Value With
                    </h4>
                    <p className='fs-24'>
                    By leveraging state-of-the-art visual experiences, innovative thinking, creative problem-solving, and data-driven insights, we create an excellent call to actions.
                    </p>
                </div>
                </div>
            
        <div className='row mb-5 px-5'>
            <Footer/>
        </div>

   
        </div>

     
  );
};

export default AvatarsPage;
